import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import {
    Button,
    Form,
    FormField,
    Input,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "semantic-ui-react";

const useStyles = createUseStyles({
    inputStyle: {
        width: "80%",
    },
    content: {
        display: "inline-block !important",
        "max-width": "90vw",
        "min-width": "400px",
    },
});

function FoodListPage() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [foodList, setFoodList] = useState([]);
    const [adding, setAdding] = useState(false);
    const [newMealText, setNewMealText] = useState("");
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth().toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const [newDateText, setNewDateText] = useState(`${year}-${month}-${day}`);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const baseUrl = "https://family-api.ericbulloch.com/food-list";

    function compareFoodItems(a, b) {
        if (a.date_eaten > b.date_eaten) return -1;
        if (a.date_eaten < b.date_eaten) return 1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    function addRecord() {
        setAdding(true);
        fetch(baseUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                name: newMealText,
                date_eaten: newDateText,
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                getRecords();
                setOpen(false);
                setNewMealText("");
                setNewDateText("");
                setAdding(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function deleteRecord(id) {
        setAdding(true);
        const url = `${baseUrl}/${id}`;
        fetch(url, { method: "DELETE" })
            .then((response) => response.json())
            .then((json) => {
                getRecords();
                setAdding(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function editRecord(id) {
        setAdding(true);
        const url = `${baseUrl}/${id}`;
        fetch(url, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                name: newMealText,
                date_eaten: newDateText,
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                getRecords();
                setEditOpen(false);
                setNewMealText("");
                setNewDateText("");
                setAdding(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function getRecords() {
        fetch(baseUrl, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => response.json())
            .then((json) => {
                setFoodList(json.sort(compareFoodItems));
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        getRecords();
    }, []);

    function renderItem(foodItem) {
        return (
            <TableRow key={foodItem.id}>
                <TableCell>{foodItem.name}</TableCell>
                <TableCell>{foodItem.date_eaten}</TableCell>
                <TableCell>
                    <Modal
                        onClose={() => setEditOpen(false)}
                        onOpen={() => setEditOpen(true)}
                        open={editOpen}
                        trigger={
                            <Button
                                icon="edit"
                                onClick={() => {
                                    setNewMealText(foodItem.name);
                                    setNewDateText(foodItem.date_eaten);
                                }}
                            />
                        }
                    >
                        <ModalHeader>Edit a meal</ModalHeader>
                        <ModalContent>
                            <Form>
                                <FormField>
                                    <Input
                                        className={classes.inputStyle}
                                        label="Meal"
                                        type="text"
                                        value={newMealText}
                                        onChange={(event) =>
                                            setNewMealText(event.target.value)
                                        }
                                        size="massive"
                                    />
                                </FormField>
                                <FormField>
                                    <Input
                                        className={classes.inputStyle}
                                        label="Date"
                                        type="text"
                                        value={newDateText}
                                        onChange={(event) =>
                                            setNewDateText(event.target.value)
                                        }
                                        size="massive"
                                    />
                                </FormField>
                            </Form>
                        </ModalContent>
                        <ModalActions>
                            <Button
                                color="black"
                                onClick={() => {
                                    setNewMealText("");
                                    setNewDateText("");
                                    setEditOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={newMealText.length === 0}
                                onClick={() => {
                                    editRecord(foodItem.id);
                                }}
                                loading={adding}
                                positive
                            >
                                Update Meal
                            </Button>
                        </ModalActions>
                    </Modal>
                </TableCell>
                <TableCell>
                    <Button
                        icon="delete"
                        onClick={() => deleteRecord(foodItem.id)}
                    />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <div className={classes.content}>
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={
                    <Button
                        primary
                        label={
                            foodList.length === 0
                                ? "Click to add a food item"
                                : null
                        }
                        icon="plus"
                    />
                }
            >
                <ModalHeader>Add a meal</ModalHeader>
                <ModalContent>
                    <Form>
                        <FormField>
                            <Input
                                className={classes.inputStyle}
                                label="Meal"
                                type="text"
                                value={newMealText}
                                onChange={(event) =>
                                    setNewMealText(event.target.value)
                                }
                                size="massive"
                            />
                        </FormField>
                        <FormField>
                            <Input
                                className={classes.inputStyle}
                                label="Date"
                                type="text"
                                value={newDateText}
                                onChange={(event) =>
                                    setNewDateText(event.target.value)
                                }
                                size="massive"
                            />
                        </FormField>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <Button
                        color="black"
                        onClick={() => {
                            setNewMealText("");
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        content="Add meal"
                        disabled={newMealText.length === 0}
                        onClick={() => addRecord()}
                        loading={adding}
                        positive
                    />
                </ModalActions>
            </Modal>
            {foodList.length > 0 && (
                <>
                    <Table celled striped>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Name</TableHeaderCell>
                                <TableHeaderCell>Date</TableHeaderCell>
                                <TableHeaderCell>Edit</TableHeaderCell>
                                <TableHeaderCell>Delete</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {foodList.map((foodItem) => {
                                return renderItem(foodItem);
                            })}
                        </TableBody>
                    </Table>
                </>
            )}
        </div>
    );
}

export default FoodListPage;
